import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const NikkeGuidesMap: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Lost Relics</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_lostrelics.png"
            alt="Lost Relics"
          />
        </div>
        <div className="page-details">
          <h1>Interactive Map</h1>
          <h2>
            An interactive map for NIKKE that shows the locations of all hidden
            objects in the main story.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Interactive map" />
        <p>
          Struggling with finding all the hidden collectibles in the story? Look
          no further! This map will show you where to find them all. Big thanks
          to{' '}
          <a
            href="https://www.youtube.com/@lootandwaifus"
            target="_blank"
            rel="noreferrer"
          >
            Loot & Waifus
          </a>{' '}
          for creating this amazing tool.
        </p>
        <p>
          Use the layer tool in top right corner to change to a different
          Chapter!
        </p>
        <iframe
          className="responsive-iframe"
          src="https://nikke-map.onrender.com/"
          width="100%"
          height="600"
          loading="lazy"
        ></iframe>
      </div>
      <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMap;

export const Head: React.FC = () => (
  <Seo
    title="Interactive Map | NIKKE | Prydwen Institute"
    description="An interactive map for NIKKE that shows the locations of all hidden objects in the main story."
  />
);
